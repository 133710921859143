.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__item {
    padding: 33px 32px 20px;
    background: $color-white;
    height: 100%;
    border: 1px solid $border-color;
    min-height: 293px;

    &--blue {
      background: $color-blue;
      color: $color-white;

      .news__title {
        font-size: 18px;
        margin: 0 0 15px;
        font-weight: 400;

        a {
          color: $color-white;
        }
      }

      .news__link {
        margin-bottom: 25px;
        display: block;
        color: $color-white;
      }

      .news__date {
        font-size: 13px;
        margin-bottom: 15px;
        color: $color-white;
      }

      .news__desc {
        color: $color-white;

        p {
          margin-bottom: 15px;
        }
      }
    }
  }

  &__title {
    font-size: 18px;
    margin: 0 0 15px;
    font-weight: 400;

    a {
      color: $text-color;
    }
  }

  &__img {
    width: 170px;
    height: 170px;
    margin-right: 40px;

    img {
      width: 100%;
      min-width: 170px;
      object-fit: cover;
    }
  }

  &__link {
    color: $color-blue;
    margin-bottom: 25px;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  &__date {
    color: $color-grey-light;
    margin-bottom: 15px;
    font-size: 12px;
  }

  &__desc {
    p {
      margin-bottom: 15px;
      font-size: 12px;
    }
  }

  .pagination {
    margin: 40px 0 60px;

    a {
      color: #bdbdbd;
    }

    li {
      &.active {
        a {
          border: 1px solid #dee2e6;
          background: $color-white;
          color: $text-color;
        }
      }

      &.disabled {
        cursor: not-allowed;

        a {
          color: #bdbdbd;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .d-flex {
      flex-direction: column;
    }

    &__item {
      min-height: inherit;
      padding: 15px;
    }

    &__img {
      margin-bottom: 15px;
    }
  }

  @include media-breakpoint-down(sm) {
    &__item {
      min-height: inherit;
      padding: 15px;
    }
  }
}


