.documents-wrapper-gap {
  margin-top: -60px;
}

.documents-wrapper {
  padding: 60px 0 20px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;

    &.home-page {
      margin-bottom: 0;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 50px;
    text-align: center;
  }

  li {

    @include media-breakpoint-down(sm) {
      width: 50%;
      height: 240px;
    }

    @media (max-width: 440px) {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;

      .document__title {
        display: block;
      }
    }
  }
}

.document {

  &__item {
    width: 100%;
    height: 277px;
    padding: 20px 40px;
    color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    background-size: cover !important;

    &--1 {
      background: url('../img/pipes/pipe-1.png') center no-repeat;
    }

    &--2 {
      background: url('../img/pipes/pipe-3.png') center no-repeat;
    }

    &--3 {
      background: url('../img/pipes/pipe-4.png') center no-repeat;
    }

    &--4 {
      background: url('../img/pipes/pipe-5.png') center no-repeat;
    }

    &--5 {
      background: url('../img/pipes/pipe-6.png') center no-repeat;
    }

    &--6 {
      background: url('../img/pipes/pipe-7.png') center no-repeat;
    }

    &--7 {
      background: url('../img/pipes/pipe-8.png') center no-repeat;
    }

    @media (max-width: 1160px) {
      padding: 20px;
    }

    @include media-breakpoint-down(sm) {
      justify-content: center;
      width: 100%;
      height: 240px;
      padding: 15px;
      text-align: center;
      align-items: center;
    }
  }

  &__download {
    background: $color-white;
    width: 100%;
    height: 277px;
    padding: 20px 40px;
    color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
      margin-top: 10px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 240px;
      padding: 15px;

      img {
        width: 100px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  &__title {
    font-size: 24px;
    margin-bottom: 15px;
    align-items: baseline;
    display: flex;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      font-weight: 400;
    }
  }

  &__desc {
    p {
      margin-bottom: 0;
    }

    a {
      color: $color-white;
      font-size: 13px;
      text-decoration: underline;
      font-weight: 600;
    }

    a:hover {
      text-decoration: none;
    }
  }
}
