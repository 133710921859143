.head-part-wrapper {
  width: 100%;
  height: 690px;
  background: url('../img/bg/main-bg.png') center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;

  @include media-breakpoint-down(md) {
    height: 400px;
  }

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../img/bg/black-pattern.png') repeat;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../img/bg/main-bg-pattern.png') repeat;
    z-index: 1;
  }
}

.head-part {
  margin: 0 auto;

  &__title {
    font-size: 44px;
    color: $color-white;
    font-weight: 400;
    z-index: 12;
    position: relative;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 25px;
      font-size: 25px;
    }
  }

  &__btn {
    z-index: 12;
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 220px;
      height: 46px;
      font-size: 19px;
    }
  }
}

.home-wrapper {
  padding: 0 0 80px;
}

.home {
  padding: 20px 70px;
  color: $color-white;
  background: url('../img/bg/about-bg.png') no-repeat;
  background-size: cover;
  height: 100%;
  max-height: 585px;
  position: relative;
  @extend .center;

  @include media-breakpoint-down(sm) {
    padding: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../img/bg/blue-transparent-pattern.png') repeat;
    z-index: 1;

  }

  &__title {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
  }

  &__desc {
    margin-bottom: 20px;

    p {
      font-size: 16px;
      position: relative;
      z-index: 2;
    }
  }
}
