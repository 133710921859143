.banner-wrapper {
  width: 100%;
  height: 300px;
  background: url('/assets/img/bg/banner-bg.png') center no-repeat;
  background-size: cover;
  position: relative;

  @include media-breakpoint-down(sm) {
    height: 200px;
    margin-top: 60px;
  }
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  color: $color-white;
  text-align: center;

  &__title {
    font-size: 32px;
    margin: 0 0 10px;
  }

  &__title,
  p {
    position: relative;
    z-index: 2;
  }

  @include media-breakpoint-down(sm) {
    height: 200px;
    padding: 20px;

    &__title {
      font-size: 20px;
    }
  }
}
