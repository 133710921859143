.list-standart-wrapper {
  background: $border-color;
  padding: 40px 0 10px;

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }
}

.list-standart {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    width: 100%;
    background: $color-white;
    margin-bottom: 30px;
    text-align: center;
    padding: 15px;

    &:hover,
    &--active {
      color: $color-white;
      background: $color-blue;
      text-decoration: none;

      .list-standart__title{
        color: $color-white;
      }
    }

    &.active {
      cursor: default;
    }
  }

  &__icon {
    width: 84px;
    height: 87px;

    img {
      width: 100%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: $color-blue;
    text-transform: uppercase;
  }

  @include media-breakpoint-down(sm) {
    &__item {
      height: 90px;
      margin-bottom: 15px;
    }

    &__icon {
      width: 60px;
      height: 60px;

      img {
        width: 100%;
      }
    }
  }
}


