.product {
  background: $color-white;
  padding: 35px;
  display: flex;
  flex-wrap: wrap;

  p {
    line-height: 30px;
    color: #6d6d6d;
  }

  @include media-breakpoint-down(sm) {

    .col-6 {
      max-width: 100%;
      flex: 100%;
      margin-bottom: 20px;
    }
  }
}
