// Tabs home page
.nav-tabs-home {
  background: $color-white;
  padding: 35px 42px 28px;
  border-bottom: 0;

  a {
    font-size: 20px;
    color: $text-color;
    padding-right: 30px;
    font-weight: 500;

    &.active {
      color: #2261a9;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 20px;

    a {
      font-size: 16px;
    }
  }
}

.tab-content-home {
  min-height: 493px;
  background: $color-white;

  &__info {
    padding: 0 42px 25px;

    &--border {
      padding: 20px 42px 0;
      border-top: 1px solid $border-color;
    }

    .news__item {
      border: 0;
      padding: 0;
      min-height: inherit;

      a {
        color: $text-color;
      }

      &:hover {
        .news__date {
          text-decoration: none;
        }
      }
    }
  }

  &__date {
    color: $color-grey-light;
    font-size: 12px;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 18px;
    margin-bottom: 20px;

    a {
      color: $text-color;
    }
  }

  &__desc {
    p {
      font-size: 14px;
      margin-bottom: 20px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(md) {
    min-height: inherit;

    &__info--border {
      padding-bottom: 20px;
    }
  }

  @include media-breakpoint-down(sm) {
    .d-flex {
      flex-direction: column;
    }
  }
}


// Tabs services page
.tabs-about-wrapper {
  background-color: #4f8fd5;
}

.tabs-content-about-wrapper {
  background: #efefef;
}

.nav-tabs--about {
  border-bottom: 0;

  a {
    width: 100%;
    height: 80px;
    font-size: 14px;
    color: $color-white;
    font-weight: 500;
    border-bottom: 5px solid #3d80c9;
    max-width: 158px;
    min-width: 158px;
    text-align: center;
    padding: auto 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active,
    &:hover {
      background-color: #3d80c9;
    }
  }

  @include media-breakpoint-down(sm) {
    li {
      width: 100%;
    }

    a {
      height: auto;
      padding: 10px;
      max-width: 100%;
      font-size: 16px;
    }
  }
}

.tab-content--about{
  background: $border-color;
  padding: 30px 0;
}


// Tabs for table
.nav-tabs--table {
  border-bottom: 0;

  .nav-link {
    margin-right: 20px;
    color: #3b3b3b;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;

    &.active {
      color: #3d80c9;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    @media (max-width: 440px) {
      padding: 0 10px;
      margin-right: 10px;
      font-size: 14px;
    }
  }

  a {
    width: 220px;
    height: 60px;
    background-color: #d4d4d4;
    color: $color-grey-dark;

    &.active {
      background: $color-white;
      color: $color-blue;
    }

    @include media-breakpoint-down(md) {
      width: auto;
      padding: 0 25px;
      height: 40px;
    }
  }
}

.tabs-content--table {
  background: $color-white;
  margin-bottom: 40px;
}
