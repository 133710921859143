.breadcrumbs-wrapper {
  background: $border-color;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 27px 0;

  &__list {
    display: flex;
    flex-wrap: wrap;

    a {
      color: $color-blue;
      font-size: 12px;
      padding-right: 10px;
      position: relative;
    }

    a::after {
      content: '-';
      position: absolute;
      top: 0;
      right: 4px;
      color: #013863;
    }

    span {
      font-size: 12px;
      color: #013863;
    }
  }
}
