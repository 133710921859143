.ssm-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  display: none;
  z-index: 50;
}

.sidebar-menu {
  background: $color-blue;
  border-right: 3px solid $color-white;
  height: 100%;
  width: 280px;
  left: 0;
  top: 0;
  z-index: 100;
  position: fixed;
  overflow-y: auto;
  overflow-x: visible;
  transform: translate(-280px,0);

  &.ssm-nav-visible {
    box-shadow: 3px 0 14px rgba(0,0,0,.4);
  }

  &__search {
    margin: 0 auto 20px;
    text-align: center;

    input {
      border: none;
      padding: 5px;
    }
  }

  &__logo {
    background: $color-white;
    margin: 30px auto;
    text-align: center;
    padding: 10px 0;

    img {
      width: 100px;
    }
  }

  &__item {
    a {
      padding: 15px 20px;
      font-size: 16px;
      color: white;
      text-decoration: none;
      display: block;
      border-bottom: 1px solid $color-white;
      transition: background 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        background: $color-white;
        color: $color-blue;
      }
    }

    &:first-child {
      a {
        border-top: 1px solid $color-white;
      }
    }
  }

  li {
    margin: 0;
  }
}

.gamburger {
  background: $color-blue-gradient;
  border-radius: 3px;
  padding: 10px 9px 8px;
  float: left;
  position: fixed;
  left: 8px;
  top: 14px;
  box-shadow: $box-shadow;
  display: none;

  &:hover {
    background: $color-blue-gradient-revert;
  }

  .bar {
    display: block;
    width: 18px;
    margin-bottom: 3px;
    height: 2px;
    background-color: #fff;
    border-radius: 1px;
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }
}
