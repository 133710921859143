.feedback-wrapper {
  background: $border-color;
  padding: 0 0 80px;
}

.feedback {
  &__left {
    @extend .center;

    color: $color-white;
    background: $color-blue;
    padding: 60px 50px;
    text-align: center;

    h3 {
      font-size: 26px;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 30px;
    }

    .btn {
      margin-bottom: 18px;
    }

    &--text-left {
      text-align: left;
    }

    &--gap {
      padding: 44px 50px;
    }

    @include media-breakpoint-down(lg) {
      &--gap {
        padding: 34px 50px;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 25px;
    }
  }

  &__right {
    background: $color-white;
    padding: 33px 40px;

    @include media-breakpoint-down(md) {
      padding: 33px 40px 60px;
    }

    @media (max-width: 450px) {
      padding: 33px 20px 60px;
    }

    .form-group {
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 4px;
        position: relative;
        font-size: 12px;

        &.require {
          span {
            position: relative;

            &::after {
              content: '*';
              position: absolute;
              top: 0;
              right: -8px;
              font-size: 12px;
              color: $color-red;
            }
          }
        }
      }

      input {
        border: 0;
        border-bottom: 1px solid #a5a5a5;
        padding: 0 0 11px;

        &:focus,
        &:hover {
          outline: none;
        }
      }

      &.error {
        input {
          border-bottom: 1px solid $color-red;
        }
      }

      textarea {
        border: 0;
        border-bottom: 1px solid #a5a5a5;
        padding: 0 0 11px;
        height: 60px;
        resize: none;

        &.error {
          input {
            border-bottom: 1px solid $color-red;
          }
        }

        @include media-breakpoint-down(lg) {
          height: 81px;
        }
      }
    }
  }

  &__upload-file {
    @extend .center;
    padding: 20px;

    height: 100%;
    text-align: center;
    background-color: #f4f4f4;

    p {
      color: #8e8e8e;
    }

    i {
      color: #8e8e8e;
      font-size: 50px;
    }

    &:hover {
      cursor: copy;

      i {
        color: darken(#8e8e8e, 5%);
      }
    }
  }

  &__btn {
    background: $clear;
    border: 0;
    position: absolute;
    bottom: -38px;
    left: calc(50% - 44px);

    &:hover,
    &:focus {
      opacity: 0.9;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 24px;
    margin-bottom: 30px;
    width: 100%;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      width: auto;
    }
  }

  &__address-details {

    li {
      font-size: 14px;
      color: $color-white;
      margin-bottom: 10px;
    }
  }
}
