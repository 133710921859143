footer {
  border: 2px solid $border-color;
  background: $color-white;
  padding: 60px 0 45px;

  @include media-breakpoint-down(sm) {
    padding: 35px 20px 25px;
  }
}

.footer {
  margin: 0 auto;
  max-width: $max-width;
  text-align: center;
  display: flex;
  justify-content: center;

  p, a {
    font-weight: 600;
    color: $color-grey;
  }

  &__phones {
    display: flex;
    margin-bottom: 15px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    p {
      padding: 0 20px;
    }

    a {
      text-decoration: none;
    }
  }

  &__mail {
    display: flex;
    margin-bottom: 40px;
    justify-content: center;

    p {
      padding: 0 20px;
    }

    a {
      border-bottom: 1px dotted $color-grey;
    }

    a:hover {
      border-bottom: 1px dotted $clear;
      text-decoration: none;
    }
  }

  &__address {
    p {
      margin-bottom: 15px;
    }
  }
}
