.contacts-wrapper {
  margin-bottom: 60px;
}

.contact {
  padding: 40px;
  background: $color-white;
  border: 1px solid $border-color;

  &--gap {
    padding: 0;
    border: 0;
  }

  &__city {
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__info {
    p {
      margin-bottom: 15px;
    }
  }

  &__address {
    background-color: #2360a6;
    padding: 30px;
    color: $color-white;

    &--blue-light {
      background-color: #3f7cc2;
    }

    &--blue {
      background-color: #2360a6;
    }

    p {
      font-size: 18px;
    }

    p + p {
      font-size: 13px;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 20px;

    &--gap {
      padding: 0;
    }

    &__address {
      padding: 20px;

      p {
        font-size: 16px;
      }

      p + p {
        font-size: 14px;
      }
    }
  }
}
