.text-format {
  padding: 60px 0;
  color: #6d6d6d;

  h2 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 25px;
  }

  p {
    margin-bottom: 25px;
    line-height: 23px;
  }

  ul {
    padding-left: 25px;
    list-style: disc;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    padding: 25px 15px 5px;

    h2 {
      font-size: 22px;
      text-align: center;
    }
  }
}

