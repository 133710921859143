.w-100 {
  width: 100%!important;
}

.hidden {
  display: none;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.show>.dropdown-menu {
  display: block;
}
