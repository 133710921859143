.nav__menu {
  background: $color-white;

  &__list {
    display: flex;
  }

  &__item {
    margin-left: 25px;

    a {
      color: $color-blue;
      font-size: 16px;
      padding-bottom: 3px;
    }

    a:hover {
      text-decoration: none;
      border-bottom: 1px solid;
    }

    &--active {
      a {
        color: $text-color;
      }

      a:hover {
        text-decoration: none;
        border-bottom: 0;
        cursor: default;
      }
    }

    @include media-breakpoint-down(md) {
      a {
        font-size: 12px;
      }
    }
  }
}

