.header {
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    box-shadow: 0 2px 2px rgba(0,0,0,.45);
  }

  &__top {
    background: $color-grey-dark;

    .header__info {
      display: flex;
      justify-content: flex-end;
      height: 60px;
      align-items: center;
      max-width: $max-width;
      width: 100%;
    }
  }

  &__bot {
    background: $color-white;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .header__nav-bar {
      display: flex;
      height: 100px;
      align-items: center;
      max-width: $max-width;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__phone {
    font-size: 15px;
    font-weight: 600;
    padding-right: 30px;
    color: $color-white;

    &:hover {
      text-decoration: none;
      color: $color-white;
    }

    @media (max-width: 358px) {
      display: none;
    }
  }

  &__mail {
    text-decoration: underline;
    font-size: 13px;
    font-weight: 600;
    padding-right: 30px;
    color: $color-white;

    &:hover {
      color: $color-white;
      text-decoration: none;
    }
  }

  &__search,
  &__auth {
    padding-right: 20px;

    i {
      color: $color-white;
      font-size: 18px;
      cursor: pointer;
    }

    i:hover {
      opacity: 0.8;
    }

    a {
      font-size: 14px;
    }

    a:hover {
      background: #3d80c9;
      color: $color-white;
    }

    input {
      margin: 5px 10px;
      font-size: 13px;
      border: 0;
      border-bottom: 1px solid rgba(0,0,0,.15);
    }
  }

  &__auth {
    padding-right: 40px;
  }

  @include media-breakpoint-down(sm) {
    &__mail,
    &__search,
    &__auth {
      display: none;
    }
  }
}
