@include media-breakpoint-down(xs) {}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(xl) {}

// Example usage:
@include media-breakpoint-up(sm) {
  .some-class {
    display: block;
  }
}
