.btn {
  min-width: 140px;
  height: 40px;
  box-shadow: $box-shadow;
  text-shadow: $text-shadow;
  color: $color-white;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border-radius: $border-radius;
  padding: 10px;
  cursor: pointer;

  &:hover {
    transition: $transition;
    color: $color-white;
  }

  &:active {
    box-shadow: 0;
  }

  &--blue {
    background-image: $color-blue-gradient;

    &:active,
    &:focus,
    &:hover {
      background-image: $color-blue-gradient-revert !important;
    }
  }

  &--medium {
    width: 240px;
  }

  &--large {
    width: 260px;
    height: 60px;
    font-size: 24px;
  }
}
