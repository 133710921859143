//---------------------------------------------------------
// Global UI Styling
//---------------------------------------------------------
body {
  font-size: $font-size;
  color: $text-color;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a {
  text-decoration: none;
  color: $color-blue;
  transition: $transition;
  outline: 0;
}

input,
select,
textarea {
  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0 0 5px;
}

main {
  background: $border-color;
}

h1 {
  font-size: 32px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 25px;
}
