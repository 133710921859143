.service {
  padding: 50px 0;

  &__item  {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__title {
    color: $color-blue;
    font-weight: 600;
    text-align: center;
    font-size: 13px;
  }

  &__img {
    margin-bottom: 5px;
    min-height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    &__item {
      padding: 0 15px;
    }

    &__title {
      font-size: 13px;
    }
  }
}
