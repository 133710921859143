// Colors
$color-white: #fff;
$color-black: #000;
$color-blue: #2261a9;
$color-grey: #6c6a6a;
$color-grey-dark: #3b3b3b;
$color-grey-light: #8f8f8f;
$color-red: #d24a43;

$clear: transparent;

// Gradient
$color-blue-gradient: linear-gradient(to top, #0d82b4 0%, #12aaeb 100%);
$color-blue-gradient-revert: linear-gradient(to top, #12aaeb 0%, #0d82b4 100%);

// Borders
$border-color: #efefef;
$border-radius: 2px;

// Body-text
$text-color: #353535;

// Animation
$transition: all 0.3s ease-in-out;

// Shadow
$box-shadow: 0 2px 2px rgba(0, 0, 0, 0.75);
$text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);

// Font family
$font-default: 'Proximanova', Helvetica, Arial, sans-serif !default;
$font-awesome: 'FontAwesome';

// Font size
$font-size: 14px;

// Container's maximum width
$max-width: 1170px !default;

